import React from 'react';

import { hasWindow } from '@dop/shared/helpers/windowDocument';

export const hasIntersectionObserver = (): boolean => {
	if (hasWindow()) {
		return (
			'IntersectionObserver' in window && 'IntersectionObserverEntry' in window
		);
	}
	return false;
};

export const useIntersectionObserver = <RefType extends HTMLElement>(
	callback: (isIntersecting: boolean) => void,
	options?: IntersectionObserverInit,
	isIntersectingDefault = false,
): React.RefObject<RefType> => {
	const targetRef = React.useRef<RefType>(null);
	const isIntersectingRef = React.useRef(isIntersectingDefault);

	React.useEffect(() => {
		if (!hasIntersectionObserver()) return undefined;
		if (!targetRef.current) return undefined;

		const intersectionObserver = new IntersectionObserver((entries) => {
			const isIntersecting = entries.some(
				({ isIntersecting }) => isIntersecting,
			);

			if (isIntersecting === isIntersectingRef.current) {
				return;
			}

			isIntersectingRef.current = isIntersecting;

			callback(isIntersecting);
		}, options);
		intersectionObserver.observe(targetRef.current);

		return () => {
			intersectionObserver.disconnect();
		};
	}, [callback, options]);

	return targetRef;
};
