import React, { Fragment } from 'react';
import { List } from 'immutable';

import { LinkInline } from '@dop/ui-composites/link/LinkInline';

import {
	filterNodes,
	ValidatedElementNode,
	ValidatedNode,
} from './markupValidate';
import { getElementRule } from './markupRulesFunctions';
import { MarkupNode } from './Markup.types';
import { ElementChildrenRules } from './markupRules';
import { defaultMarkupResolvers, MarkupResolvers } from './markupResolvers';
import { MarkupError } from './markupElements/MarkupError';

export const getMarkupElement = (
	node: ValidatedElementNode,
	markupResolvers = defaultMarkupResolvers,
) => {
	if (markupResolvers == null || markupResolvers.length === 0) return null;

	// Loop through each resolver to see if you have a hit (condition === true).
	for (const { condition, component } of markupResolvers) {
		if (condition != null && component != null && condition(node) === true)
			return component;
	}
	return null;
};

const renderNode = ({
	node,
	ancestorRules,
	markupResolvers,
	index,
}: {
	node?: ValidatedNode;
	ancestorRules: ElementChildrenRules;
	markupResolvers: MarkupResolvers;
	index: number;
}) => {
	if (node == null) return null;

	if ('tag' in node) {
		// Try to render a special markup-element first. If that does not render anything (returns null), render node as a regular element
		const MarkupElement = getMarkupElement(node, markupResolvers);
		if (MarkupElement == null) return null;

		return (
			<Fragment key={index}>
				<MarkupElement
					node={node}
					ancestorRules={ancestorRules}
					markupResolvers={markupResolvers}
				/>
				{node.attributes?.['data-duplicate-id'] != null && (
					<MarkupError>
						{`Duplicate ID: `}
						<LinkInline
							href={`#${node.attributes?.id ?? ''}`}
							linkType="markupError"
							$textDecorationLine="underline"
						>
							{`#`}
							{node.attributes?.id ?? ''}
						</LinkInline>
					</MarkupError>
				)}
			</Fragment>
		);
	}

	// Not an element and not null? Must be text
	return node.text;
};

// Recursively walk through nodes and node-by-node render them into React Components.
// The recursive loop is spread over multiple functions:
// renderNodes > renderNode > renderElement > (back into) renderNodes
export const renderNodes = (
	nodes: Array<MarkupNode> | undefined,
	childrenRules: ElementChildrenRules,
	markupResolvers: MarkupResolvers,
) => {
	if (nodes == null || nodes.length === 0) {
		return [];
	}

	const filteredNodes = filterNodes(nodes, childrenRules);

	return filteredNodes.map((node, index) =>
		renderNode({
			node,
			ancestorRules: childrenRules,
			markupResolvers,
			index,
		}),
	);
};

type DefaultTag =
	| keyof JSX.IntrinsicElements
	| undefined
	| typeof React.Fragment;

type MarkupProps<Tag extends DefaultTag> = {
	html?: Array<MarkupNode> | List<MarkupNode>;
	tag?: Tag;
	rulesContextTag?: keyof JSX.IntrinsicElements;
	markupResolvers?: MarkupResolvers;
} & JSX.IntrinsicElements[Tag extends keyof JSX.IntrinsicElements
	? Tag
	: 'div'];

/**
 * Renders a block of HTML code, structured as a plain JS array or an ImmutableJS List into React-components
 */
export const Markup = <Tag extends DefaultTag>({
	tag = 'div',
	html,
	rulesContextTag = typeof tag === 'string' ? tag : 'div',
	markupResolvers = defaultMarkupResolvers,
	...elementProps
}: MarkupProps<Tag>) => {
	// Only create new root nodes when necessary so we dont do unneeded rerenders
	const nodes = React.useMemo(() => {
		// isArray check for typescript
		if (!Array.isArray(html) && html instanceof List) {
			return html.toJS();
		}
		return html;
	}, [html]);

	if (nodes == null) return null;

	const childrenRules = getElementRule(rulesContextTag)?.children;
	if (childrenRules == null) return null;

	const children = renderNodes(nodes, childrenRules, markupResolvers);
	if (children.length === 0) return null;

	return React.createElement(tag, { ...elementProps }, children);
};
