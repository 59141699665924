import { stringOnly } from '@dop/shared/helpers/string';

import { ElementNode, MarkupNode } from './Markup.types';
import { ElementChildrenRules } from './markupRules';
import { filterNode, ValidatedElementNode } from './markupValidate';

export const hasClassName = (node: ElementNode, className: string) => {
	const classNames = node.attributes?.className?.trim().split(/\s/);

	return classNames?.includes(className) ?? false;
};

// recursively travel through the HTML-tree to get the text-only version of the content
export const renderTextOnly = (nodes: Array<MarkupNode> | undefined) => {
	let result = '';
	if (nodes == null) return result;

	for (const node of nodes) {
		if ('text' in node) {
			result += stringOnly(node.text) ?? '';
			continue;
		}

		result += renderTextOnly(node.children);
	}
	return result;
};

/** dept first search of Node */
export const findNode = (
	root: MarkupNode,
	ancestorRules: ElementChildrenRules,
	selector: (markupNode: ElementNode) => boolean,
): ValidatedElementNode | undefined => {
	if (!('tag' in root)) return undefined;

	if (selector(root)) {
		const filteredRoot = filterNode(root, ancestorRules);

		if (
			filteredRoot != null &&
			!Array.isArray(filteredRoot) &&
			'tag' in filteredRoot
		) {
			return filteredRoot;
		}
	}

	if (root.children == null) return undefined;

	for (const child of root.children) {
		const result = findNode(child, ancestorRules, selector);

		if (result != null) {
			return result;
		}
	}

	return undefined;
};
