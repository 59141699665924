import React from 'react';
import { css } from 'styled-components';

import { ChevronLink } from '@dop/ui-composites/link/ChevronLink';
import {
	FilledBoxLink,
	FilledBoxText,
} from '@dop/ui-composites/link/FilledBoxLink';
import {
	ShelfLinkBlock,
	ShelfLinkIcon,
	ShelfLinkText,
} from '@dop/ui-composites/link/ShelfLink';
import { WithLinkTypeIcon } from '@dop/ui-composites/link/WithLinkTypeIcon';
import { IconChevronLeft } from '@dop/ui-icons/svg/chevronLeft';

import { MarkupError } from './markupElements/MarkupError';
import { renderTextOnly } from './markupNode.helpers';
import { MarkupElementProps } from './markupResolvers';
import { ValidatedElementNode } from './markupValidate';

const specialLinkVariants = ['cta', 'forward', 'backward'];

export const isSpecialLink = (node: ValidatedElementNode) => {
	const dataDopLinkVariant = node.attributes?.['data-dop-link'];

	return (
		node.tag === 'a' &&
		dataDopLinkVariant != null &&
		specialLinkVariants.includes(dataDopLinkVariant)
	);
};

export type MarkupSpecialLinkProps = MarkupElementProps;

const linkMargin = css`
	margin-block: 1rem;
`;

/**
 * Special links are used for call-to-action buttons, or chevron (either forward or backward) links.
 * In the Bloomreach editor, place the cursor on any link and select from the options in the styles-dropdown field.
 */
export const MarkupSpecialLink = (
	props: MarkupElementProps,
): React.ReactElement | null => {
	const linkVariant = props.node?.attributes?.['data-dop-link'];

	// Strip any special characters, etc from the contents of the link-text
	const linkText = renderTextOnly(props.node.children);
	const href = props.node?.attributes?.href;

	if (href == null)
		return <MarkupError>{'No valid href provided for this link'}</MarkupError>;

	switch (linkVariant) {
		case 'cta':
			return (
				<FilledBoxLink
					{...props}
					href={href}
					linkType="special-call-to-action-link"
					css={linkMargin}
				>
					<FilledBoxText href={href}>{linkText}</FilledBoxText>
				</FilledBoxLink>
			);
		case 'forward':
			return (
				<ChevronLink
					{...props}
					href={href}
					linkType="special-forward-link"
					css={linkMargin}
				>
					{linkText}
				</ChevronLink>
			);
		case 'backward':
			return (
				<ShelfLinkBlock
					{...props}
					href={href}
					linkType="special-backward-link"
					css={linkMargin}
				>
					<ShelfLinkIcon as={IconChevronLeft} />
					<ShelfLinkText>
						<WithLinkTypeIcon href={href}>{linkText}</WithLinkTypeIcon>
					</ShelfLinkText>
				</ShelfLinkBlock>
			);
		default:
			return null;
	}
};
