import { kebabCase, stringOnly } from '@dop/shared/helpers/string';

import { ElementNode, MarkupNode } from './Markup.types';
import { ElementChildrenRules } from './markupRules';
import {
	getElementRule,
	isIframeViolated,
	isPhrasingElement,
	isInteractiveElement,
	isFlowElement,
	isAttributeAllowed,
	filterClassNames,
} from './markupRulesFunctions';

const validatedSymbol = Symbol('shallow validated');

type ValidatedAttributes = {
	[validatedSymbol]: 'SHALLOW_VALIDATED_ATTRIBUTES';
};
type ValidatedElement = {
	[validatedSymbol]: 'SHALLOW_VALIDATED_NODE';
};

export type ElementNodeWithValidatedAttributes = ElementNode &
	ValidatedAttributes;
export type ValidatedElementNode = ElementNode & ValidatedElement;
export type ValidatedNode = MarkupNode & ValidatedElement;

const articleIdPrefix = 'art:';

/**
 * Slugify ID and prepend ID prefix
 */
export const prefixIdInArticle = (nodeId: string) => {
	const prefixRegex = new RegExp(`^${articleIdPrefix}`);
	const unprefixedId = nodeId.replace(prefixRegex, '');

	const id = `${articleIdPrefix}${kebabCase(unprefixedId)}`;

	return id;
};

export const isValidated = (
	node: MarkupNode | ValidatedNode,
): node is ValidatedNode => {
	return (
		validatedSymbol in node &&
		node[validatedSymbol] === 'SHALLOW_VALIDATED_NODE'
	);
};

export const invalidateNode = (node: ValidatedNode): MarkupNode => {
	const newNode = { ...node };
	Reflect.deleteProperty(newNode, validatedSymbol);

	return newNode;
};

export const filterAttributes = (
	node: ElementNode,
): ElementNodeWithValidatedAttributes => {
	const { id, className, ...attributes } = node.attributes ?? {};

	const filteredAttributes: Record<string, string | undefined> =
		Object.fromEntries(
			Object.entries(attributes).filter(([attributeKey]) =>
				isAttributeAllowed(attributeKey),
			),
		);

	if (className != null) {
		filteredAttributes.className = filterClassNames(className);
	}

	if (id != null && id.length > 0) {
		filteredAttributes.id = prefixIdInArticle(id);
	}

	return {
		...node,
		attributes: filteredAttributes,
		[validatedSymbol]: 'SHALLOW_VALIDATED_ATTRIBUTES',
	};
};

/**
 * Filter element. If it is not allowed, return its children instead.
 */
// eslint-disable-next-line complexity
const filterElementNode = (
	node: ElementNodeWithValidatedAttributes,
	childrenRules: ElementChildrenRules,
): ValidatedElementNode | Array<MarkupNode> | undefined => {
	const elementRule = getElementRule(node.tag);

	if (elementRule == null) return node.children;

	if (isIframeViolated(node)) {
		return node.children;
	}

	if (childrenRules.forbidInteractive && isInteractiveElement(elementRule)) {
		return node.children;
	}

	if (childrenRules.alsoElements?.includes(node.tag)) {
		return { ...node, [validatedSymbol]: 'SHALLOW_VALIDATED_NODE' };
	}

	if (
		childrenRules.onlyElements != null &&
		childrenRules.onlyElements.length > 0
	) {
		return childrenRules.onlyElements.includes(node.tag)
			? { ...node, [validatedSymbol]: 'SHALLOW_VALIDATED_NODE' }
			: node.children;
	}

	if (childrenRules.onlyPhrasing && !isPhrasingElement(elementRule)) {
		return node.children;
	}

	if (isFlowElement(elementRule)) {
		return { ...node, [validatedSymbol]: 'SHALLOW_VALIDATED_NODE' };
	}

	return node.children;
};

export const filterNode = (
	node: MarkupNode,
	childrenRules: ElementChildrenRules,
): ValidatedNode | Array<MarkupNode> | undefined => {
	if (childrenRules.empty) {
		return undefined;
	}

	if ('text' in node) {
		if (
			childrenRules.onlyElements != null &&
			childrenRules.onlyElements.length > 0
		) {
			return undefined;
		}

		return {
			text: stringOnly(node.text),
			[validatedSymbol]: 'SHALLOW_VALIDATED_NODE',
		};
	}

	const nodeWithValidatedAttributes = filterAttributes(node);

	return filterElementNode(nodeWithValidatedAttributes, childrenRules);
};

export const filterNodes = (
	nodes: Array<MarkupNode> | undefined,
	childrenRules: ElementChildrenRules,
): Array<ValidatedNode> => {
	if (nodes == null) return [];

	const filteredNodes: Array<ValidatedNode> = [];

	for (const node of nodes) {
		const filteredNode = filterNode(node, childrenRules);

		if (filteredNode == null) continue;

		if (Array.isArray(filteredNode)) {
			const filteredChildNodes = filterNodes(filteredNode, childrenRules);
			filteredNodes.push(...filteredChildNodes);
			continue;
		}

		filteredNodes.push(filteredNode);
	}

	return filteredNodes;
};

/**
 * Validate single element, primarily used for quick validation in unit-tests
 */
export const validateElementNode = (
	node: ElementNode,
): ValidatedElementNode => {
	const elementRule = getElementRule(node.tag);

	if (elementRule == null) throw new Error(`Unknown element ${node.tag}`);

	const validNode = filterNode(node, elementRule);

	if (Array.isArray(validNode) || validNode == null || !('tag' in validNode))
		throw new Error(`Unexpected element node validation`);

	return validNode;
};
