import styled, { css } from 'styled-components';
import { Property as CSSProperty } from 'csstype';

import { StyledComponentPropsWithoutRef } from '@dop/shared/typeHelpers/StyledComponentPropsWithoutRef';

import {
	BaseStaticStyleProps,
	baseStaticStyleMap,
	baseStyle,
} from '../base/Base';
import { getCSSFromStyleProps } from '../base/getCSSFromStyleProps';
import {
	ComponentStyleMap,
	ComponentStyleProps,
	StyleMap,
} from '../base/StyleProps.types';
import { getStringOrRootCapArrayCSSValue } from '../base/commonStyleFunctions';
import { ShapeColor } from '../colorTheme/ColorTheme.types';
import { getThemedColorValue } from '../colorTheme/colorThemeStyleFunctions';
import { Gap } from '../layout/layoutDefinitions';
import { getGapCSS } from '../layout/layoutStyleFunctions';
import {
	getPaddingBlockCSS,
	getPaddingInlineCSS,
} from '../planeDivision/planeDivisionStyleFunctions';
import {
	PaddingBlock,
	PaddingInline,
} from '../planeDivision/planeDivisionDefinitions';

export type ScrollStaticStyleProps = BaseStaticStyleProps & {
	/** `overflow-inline` is at the moment only supported in Firefox so it cannot be used yet.
	 * When support broadens the properties should be replaces with `overflow-inline`.
	 */
	$overflowX?: Extract<CSSProperty.OverflowX, 'auto' | 'scroll' | 'hidden'>;
	/** `overflow-block` is at the moment only supported in Firefox so it cannot be used yet.
	 * When support broadens the properties should be replaces with `overflow-block`.
	 */
	$overflowY?: Extract<CSSProperty.OverflowY, 'auto' | 'scroll' | 'hidden'>;

	$scrollBehavior?: CSSProperty.ScrollBehavior;
	$overscrollBehavior?: CSSProperty.OverscrollBehavior;

	/**
	 * Can be used to make carousels where the children need to cover the entire area.
	 * In this case the children need to have size 100% to cover the entire scroll area.
	 * That's only possible if they are direct children.
	 *
	 * This will also set `display: flex`.
	 */
	$flexDirection?: CSSProperty.FlexDirection;
	$gap?: Gap;

	$scrollSnapType?: CSSProperty.ScrollSnapType;
	$paddingInline?: PaddingInline;
	$paddingBlock?: PaddingBlock;
	$scrollPaddingInline?:
		| CSSProperty.ScrollPaddingInline
		| number
		| [
				CSSProperty.ScrollPaddingInlineStart | number,
				CSSProperty.ScrollPaddingInlineEnd | number,
		  ];
	$scrollPaddingBlock?:
		| CSSProperty.ScrollPaddingBlock
		| number
		| [
				CSSProperty.ScrollPaddingBlockStart | number,
				CSSProperty.ScrollPaddingBlockEnd | number,
		  ];

	/**
	 * Scrollbar styling according to W3C standards is only supported in Firefox at this moment.
	 * To make fallback feasable for webkit browsers all scrollbar properties have to be used together.
	 * That's why the made up `scrollbar` property is used to combine `scrollbar-width` and `scrollbar-color`.
	 * Once W3C standards for styling scrollbars is more widely supported,
	 * this `$scrollbar` prop should be split up into those three props.
	 */
	$scrollbar?:
		| 'none'
		| ['auto', ShapeColor, ShapeColor]
		| ['thin', ShapeColor, ShapeColor];
	/**
	 * `scrollbar-gutter` is more widely supported although Safari support is missing at this moment.
	 */
	$scrollbarGutter?: CSSProperty.ScrollbarGutter;
};

export type ScrollComponentStyleProps =
	ComponentStyleProps<ScrollStaticStyleProps>;

// Measurements were taken on a Windows laptop.
// They are a bit arbitrary but the best we can do for the fallback.
const scrollbarWidthMap = {
	thin: '8px',
	auto: '17px',
} as const;

export const scrollStaticStyleMap: StyleMap<ScrollStaticStyleProps> = {
	...baseStaticStyleMap,
	$overflowX: (overflowX) =>
		overflowX != null &&
		css`
			overflow-x: ${overflowX};
		`,
	$overflowY: (overflowY) =>
		overflowY != null &&
		css`
			overflow-y: ${overflowY};
		`,
	$scrollBehavior: (scrollBehavior) =>
		scrollBehavior != null &&
		css`
			scroll-behavior: ${scrollBehavior};
		`,
	$overscrollBehavior: (overscrollBehavior) =>
		overscrollBehavior != null &&
		css`
			overscroll-behavior: ${overscrollBehavior};
		`,
	$flexDirection: (flexDirection) =>
		flexDirection != null &&
		css`
			display: flex;
			flex-direction: ${flexDirection};
		`,
	$gap: getGapCSS,
	$scrollSnapType: (scrollSnapType) =>
		scrollSnapType != null &&
		css`
			scroll-snap-type: ${scrollSnapType};
		`,
	$paddingInline: getPaddingInlineCSS,
	$paddingBlock: getPaddingBlockCSS,
	$scrollPaddingInline: (scrollPaddingInline) =>
		scrollPaddingInline != null &&
		css`
			scroll-padding-inline: ${getStringOrRootCapArrayCSSValue(
				scrollPaddingInline,
			)};
		`,
	$scrollPaddingBlock: (scrollPaddingBlock) =>
		scrollPaddingBlock != null &&
		css`
			scroll-padding-block: ${getStringOrRootCapArrayCSSValue(
				scrollPaddingBlock,
			)};
		`,
	$scrollbar: (scrollbar = ['thin', `hsl(0, 0%, 80%)`, `hsl(0, 0%, 95%)`]) => {
		if (scrollbar === 'none') {
			// Don't show any scrollbar. The element can still be scrolled.
			return css`
				scrollbar-width: none;

				&::-webkit-scrollbar {
					inline-size: 0;
					block-size: 0;
				}
			`;
		}

		const [scrollbarWidth, foregroundThemedColor, backgroundThemedColor] =
			scrollbar;

		const foregroundColor = getThemedColorValue(foregroundThemedColor);
		const backgroundColor = getThemedColorValue(backgroundThemedColor);

		return css`
			scrollbar-width: ${scrollbarWidth};
			scrollbar-color: ${foregroundColor} ${backgroundColor};

			/* Without the outer parentheses styled-components messes the @supports syntax up when using not. */
			@supports (not (scrollbar-width: thin)) {
				&::-webkit-scrollbar {
					inline-size: ${scrollbarWidthMap[scrollbarWidth]};
					block-size: ${scrollbarWidthMap[scrollbarWidth]};
				}

				&::-webkit-scrollbar-thumb {
					background: ${foregroundColor};
					border-radius: 999px;
					/* Only way to add margin around the thumb */
					border: 1px solid ${backgroundColor};
				}
				&::-webkit-scrollbar-track {
					background: ${backgroundColor};
				}
			}
		`;
	},
	$scrollbarGutter: (scrollbarGutter) =>
		scrollbarGutter != null &&
		css`
			scrollbar-gutter: ${scrollbarGutter};
		`,
};

const scrollStyleMap: ComponentStyleMap<ScrollStaticStyleProps> = {
	normal: scrollStaticStyleMap,
};

/**
 * Make an element scroll to the viewport.
 *
 * Responsibilities:
 *
 * 1. Make element scrollable in inline or block direction.
 * 2. Control overscroll behaviour.
 * 3. Make scrollbar stylable across browsers.
 * 4. Make scrolling snap to children. Use with ScrollSnapItem as direct child.
 */
export const Scroll = styled.span.attrs<ScrollComponentStyleProps>(
	({ tabIndex = 0 }) => ({
		// For accessibility reasons a scroll area should be focusable
		// so it can be scrolled by keyboard
		tabIndex,
	}),
)<ScrollComponentStyleProps>`
	${(props) => {
		return css`
			${baseStyle};
			display: block;

			${getCSSFromStyleProps(scrollStyleMap, props)};
		`;
	}};
`;

export type ScrollProps = StyledComponentPropsWithoutRef<typeof Scroll>;

export type ScrollSnapItemStaticStyleProps = BaseStaticStyleProps & {
	/** Mandatory, because without this property set this element does nothing */
	$scrollSnapAlign: CSSProperty.ScrollSnapAlign;
	$scrollSnapStop?: CSSProperty.ScrollSnapStop;
	$scrollMarginInline?:
		| CSSProperty.ScrollMarginInline
		| number
		| [
				CSSProperty.ScrollMarginInlineStart | number,
				CSSProperty.ScrollMarginInlineEnd | number,
		  ];
	$scrollMarginBlock?:
		| CSSProperty.ScrollMarginBlock
		| number
		| [
				CSSProperty.ScrollMarginBlockStart | number,
				CSSProperty.ScrollMarginBlockEnd | number,
		  ];
};

export type ScrollSnapItemComponentStyleProps =
	ComponentStyleProps<ScrollSnapItemStaticStyleProps>;

export const scrollSnapItemStaticStyleMap: StyleMap<ScrollSnapItemStaticStyleProps> =
	{
		...baseStaticStyleMap,
		$flex: (flex = 'none') => baseStaticStyleMap.$flex(flex),
		$scrollSnapStop: (scrollSnapStop) =>
			scrollSnapStop != null &&
			css`
				scroll-snap-stop: ${scrollSnapStop};
			`,
		$scrollSnapAlign: (scrollSnapAlign) =>
			scrollSnapAlign != null &&
			css`
				scroll-snap-align: ${scrollSnapAlign};
			`,
		$scrollMarginInline: (scrollMarginInline) =>
			scrollMarginInline != null &&
			css`
				scroll-margin-inline: ${getStringOrRootCapArrayCSSValue(
					scrollMarginInline,
				)};
			`,
		$scrollMarginBlock: (scrollMarginBlock) =>
			scrollMarginBlock != null &&
			css`
				scroll-margin-block: ${getStringOrRootCapArrayCSSValue(
					scrollMarginBlock,
				)};
			`,
	};

const scrollSnapItemStyleMap: ComponentStyleMap<ScrollSnapItemStaticStyleProps> =
	{
		normal: scrollSnapItemStaticStyleMap,
	};

/**
 * Use as a child of Scroll to define snapping points when scrolling.
 *
 * Responsibilities:
 *
 * 1. Control snapping behaviour
 * 2. Set scroll margin
 */
export const ScrollSnapItem = styled.span<ScrollSnapItemComponentStyleProps>`
	${(props) => {
		return css`
			${baseStyle};
			display: block;

			${getCSSFromStyleProps(scrollSnapItemStyleMap, props)};
		`;
	}};
`;

export type ScrollSnapItemProps = StyledComponentPropsWithoutRef<
	typeof ScrollSnapItem
>;
