import React from 'react';
import styled, { css } from 'styled-components';
import { Property as CSSProperty } from 'csstype';

import { StyledComponentPropsWithoutRef } from '@dop/shared/typeHelpers/StyledComponentPropsWithoutRef';

import {
	BaseStaticStyleProps,
	baseStaticStyleMap,
	baseStyle,
} from '../base/Base';
import { getCSSFromStyleProps } from '../base/getCSSFromStyleProps';
import {
	ComponentStyleMap,
	ComponentStyleProps,
	StyleMap,
} from '../base/StyleProps.types';
import { getColumnsGapCSS } from './layoutStyleFunctions';
import { Gap } from './layoutDefinitions';

export type ColumnFill = Exclude<
	CSSProperty.ColumnFill,
	'balance-all' | '-moz-initial'
>;

export type ColumnsStaticStyleProps = BaseStaticStyleProps & {
	$count?: CSSProperty.ColumnCount;
	$fill?: ColumnFill;
	$rule?: CSSProperty.ColumnRule;
	$gap?: Gap;
	$columnWidth?: number;
};

export type ColumnsComponentStyleProps =
	ComponentStyleProps<ColumnsStaticStyleProps>;

export const columnsStaticStyleMap: StyleMap<ColumnsStaticStyleProps> = {
	...baseStaticStyleMap,

	$count: (count) => css`
		column-count: ${count};
	`,
	$fill: (fill = 'balance') => css`
		column-fill: ${fill};
	`,
	$gap: getColumnsGapCSS,
	$rule: (rule) => css`
		column-rule: ${rule};
	`,
	$columnWidth: (columnWidth) =>
		columnWidth != null &&
		css`
			column-width: calc(${columnWidth} * var(--root-cap));
		`,
};

const columnsStyleMap: ComponentStyleMap<ColumnsStaticStyleProps> = {
	normal: columnsStaticStyleMap,
};

/**
 * Columns
 *
 * CSS Columns have its limititations. To overcome these limitiations every direct
 * child of Columns needs to be a ColumnsItem component. The problems this solves are:
 *
 * 1. Prevent breaking a single item over multiple columns.
 * 2. CSS columns has no concept of row gaps, only column gaps.
 *    The ColumnsItem can take care of the row gaps.
 *    This is handled via a CSS Custom Property on the Columns component.
 * 3. To make components fit together in any layout there should be no
 *    space arround elements. Columns and Columns Item handle this together.
 */
export const Columns = styled.span<ColumnsComponentStyleProps>`
	${(props) => {
		return css`
			${baseStyle};
			display: block;
			pointer-events: none;

			margin-block: calc(-0.5 * var(--columns-row-gap));

			${getCSSFromStyleProps(columnsStyleMap, props)};
		`;
	}};
`;

export type ColumnsProps = StyledComponentPropsWithoutRef<typeof Columns>;

/* ==== ColumnsItem ==== */

export type ColumnsItemStaticStyleProps = BaseStaticStyleProps & {
	$span?: 'none' | 'all';
};

export type ColumnsItemComponentStyleProps =
	ComponentStyleProps<ColumnsItemStaticStyleProps>;

export const columnsItemStaticStyleMap: StyleMap<ColumnsItemStaticStyleProps> =
	{
		...baseStaticStyleMap,

		$span: (span) => css`
			column-span: ${span};
		`,
	};

const columnsItemStyleMap: ComponentStyleMap<ColumnsItemStaticStyleProps> = {
	normal: columnsItemStaticStyleMap,
};

/**
 * ColumnItem should always be used as a direct child of <Columns>
 */
export const ColumnsItem = styled.span.attrs((props) => ({
	// Why this is needed:
	//
	// The columns item cannot regulate the vertical gap
	// with margin because it would be outside of the element
	// and thus possibly wrap in the CSS Column. That makes
	// spacing unpredictable and impossible to get right.
	//
	// Therefore padding is used instead. This brings with it
	// a new problem unfortunately: it can overlap other elements
	// and make it impossible to click those elements.
	//
	// The solution is to disable pointer-events on the element
	// with the padding, so the padding can't be clicked. We do
	// however need to restore the pointer events inside
	// of the ColumnsItem. This children wrapper does just that.
	children: (
		<span
			css={`
				display: block;
				pointer-events: auto;
			`}
		>
			{props.children}
		</span>
	),
}))<ColumnsItemComponentStyleProps>`
	${(props) => {
		return css`
			${baseStyle};
			/* inline-block and 100%-width to prevent Firefox bug in which column-items would be hidden on certain breakpoints */
			display: inline-block;
			width: 100%;
			vertical-align: top;
			pointer-events: none;
			break-inside: avoid;
			/* in case this element is used as an img */
			max-width: 100%;
			padding-block: calc(var(--columns-row-gap) / 2);

			${getCSSFromStyleProps(columnsItemStyleMap, props)};
		`;
	}};
`;

export type ColumnsItemProps = StyledComponentPropsWithoutRef<
	typeof ColumnsItem
>;
