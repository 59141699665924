import environmentConfig from 'moduleAlias/environmentConfig';

import { ElementNode } from './Markup.types';
import {
	elementRules,
	iframeSourceWhitelist,
	attributeWhitelist,
	classNameWhitelist,
	ElementChildrenRules,
	ElementRule,
	elementType,
} from './markupRules';

export const getElementRule = (tag: string) =>
	elementRules.find((rule) => rule.element === tag);
// Allowed htmlTags are whitelisted

export const isIframeSrcAllowed = (src?: string) => {
	if (src == null) return false;

	const { hostname } = new URL(src, environmentConfig.originUrl);

	if (!hostname) return false;

	return iframeSourceWhitelist.some((whitelistedSource) =>
		whitelistedSource.test(hostname),
	);
};
// Allowed attributes are whitelisted

export const isAttributeAllowed = (attributeName?: string) => {
	if (attributeName == null) return false;
	return attributeWhitelist.some((whitelistedAttributeName) =>
		whitelistedAttributeName instanceof RegExp
			? whitelistedAttributeName.test(attributeName)
			: whitelistedAttributeName === attributeName,
	);
};

export const filterClassNames = (classNames?: string): string | undefined => {
	if (classNames == null) return undefined;

	return classNames
		.trim()
		.split(/\s+/s)
		.filter((className) =>
			classNameWhitelist.some(
				(whitelistedClassName) => whitelistedClassName === className,
			),
		)
		.join(' ');
};

// Allowed htmlTags are whitelisted
export const isIframeViolated = (node: ElementNode) => {
	const tag = node.tag.toLowerCase();
	const src = node.attributes?.src;

	return tag === 'iframe' && !isIframeSrcAllowed(src);
};

export const mergeRules = (
	ancestorRules: ElementChildrenRules,
	childRules: ElementChildrenRules,
) => {
	const onlyPhrasing = ancestorRules.onlyPhrasing || childRules.onlyPhrasing;
	const forbidInteractive =
		ancestorRules.forbidInteractive || childRules.forbidInteractive;
	return { ...childRules, onlyPhrasing, forbidInteractive };
};

export const mergeElementRule = (
	ancestorRules: ElementChildrenRules,
	node: ElementNode | undefined,
) => {
	if (node == null) return ancestorRules;

	const elementRule = getElementRule(node.tag);

	if (elementRule == null) return ancestorRules;

	const mergedRules = mergeRules(ancestorRules, elementRule.children);

	return mergedRules;
};

export const isPhrasingElement = (elementRule: ElementRule) =>
	elementRule.contentCategories?.includes(elementType.phrasing) ?? false;

export const isInteractiveElement = (elementRule: ElementRule) =>
	elementRule.contentCategories?.includes(elementType.interactive) ?? false;

export const isFlowElement = (elementRule: ElementRule) =>
	elementRule.contentCategories?.includes(elementType.flow) ?? false;
