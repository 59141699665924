import React from 'react';
import styled, { css } from 'styled-components';
import { transparentize } from 'polished';

import { IconChevronLeft } from '@dop/ui-icons/svg/chevronLeft';
import { IconChevronRight } from '@dop/ui-icons/svg/chevronRight';
import { SideScroll } from '@dop/shared/components/sideScroll/sideScroll';
import { zIndexSideScrollAfter } from '@dop/shared/constants/zIndex';

import { grijs, hemelblauw, wit } from '../../styles/colors';
import { fontSize } from '../../styles/mixins/fontSize';
import { cleanButton } from '../../styles/mixins/button';
import { focusStyle, noFocusStyle } from '../../styles/mixins/focus';

const sideScrollButtonSize = '1.5rem';

const SideScrollButtonContainer = styled.div`
	pointer-events: none;
	margin-left: calc(${sideScrollButtonSize} / -2);
	margin-right: calc(${sideScrollButtonSize} / -2);
	display: flex;
	align-items: flex-start;
	grid-area: main;
`;

const SideScrollButton = styled.button`
	${cleanButton};
	appearance: none;
	flex: none;
	pointer-events: all;
	z-index: ${zIndexSideScrollAfter};
	height: 100%;
	width: calc(2rem + ${sideScrollButtonSize} / 2);
	display: flex;
	flex-direction: column;
	justify-content: flex-start;

	&:focus {
		${noFocusStyle};
	}
`;

const SideScrollButtonHandle = styled.span`
	position: sticky;
	display: block;
	top: calc(50% - ${sideScrollButtonSize} / 2);
	width: ${sideScrollButtonSize};
	height: ${sideScrollButtonSize};

	${SideScrollButton}:focus & {
		${focusStyle};
	}
`;
const SideScrollButtonLeft = (props) => (
	<SideScrollButton
		{...props}
		css={`
			margin-right: auto;
			align-items: flex-start;
			background-image: ${() => {
				return `linear-gradient(to right, ${wit} calc(${sideScrollButtonSize} / 2), ${transparentize(
					1,
					wit,
				)})`;
			}};
		`}
	>
		<SideScrollButtonHandle>
			<IconChevronLeft
				css={`
					width: 100%;
					height: 100%;
				`}
			/>
		</SideScrollButtonHandle>
	</SideScrollButton>
);
const SideScrollButtonRight = (props) => (
	<SideScrollButton
		{...props}
		css={`
			margin-left: auto;
			align-items: flex-end;
			background-image: ${() => {
				return `linear-gradient(to left, ${wit} calc(${sideScrollButtonSize} / 2), ${transparentize(
					1,
					wit,
				)})`;
			}};
		`}
	>
		<SideScrollButtonHandle>
			<IconChevronRight
				css={`
					width: 100%;
					height: 100%;
				`}
			/>
		</SideScrollButtonHandle>
	</SideScrollButton>
);

const cellStyle = css`
	text-align: left;
	border-bottom: 1px solid ${grijs.light};
	padding: 0.2em 0.6em;
	color: ${hemelblauw.darkest};
	vertical-align: top;
	text-align: left;
	max-width: 12rem;
`;
export const Table = ({ children, ...tableProps }) => {
	const id = `tableScrollArea-${React.useId()}`;
	return (
		<SideScroll
			css={`
				width: 100%;
				margin-top: 2.2rem;
				margin-bottom: 1rem;
				display: grid;
				grid-template-rows: minmax(0, 1fr);
				grid-template-columns: minmax(0, 1fr);
				grid-template-areas: 'main';
			`}
			scrollRatio={0.5}
			minScrollDelta={200}
			scrollAreaId={id}
			ButtonContainer={SideScrollButtonContainer}
			ButtonLeft={SideScrollButtonLeft}
			ButtonRight={SideScrollButtonRight}
			scrollAreaCss={css`
				grid-area: main;
			`}
			scrollContentCss={css`
				min-width: 100%;
			`}
		>
			<table
				css={`
					border-collapse: collapse;
					border-spacing: 0;
					word-wrap: break-word;
					${fontSize('small')};
					width: 100%;
					overflow: auto;
				`}
				{...tableProps}
			>
				{children}
			</table>
		</SideScroll>
	);
};

export const Caption = styled.caption`
	font-weight: bold;
	color: ${hemelblauw.darkest};
	background-color: ${wit};
	top: 0;
	padding: 0.3em 1em;
	max-width: 100%;
	${fontSize(['h3', 'all'])};
`;

export const Th = styled.th`
	${cellStyle};

	${({ scope }) =>
		scope === 'row' &&
		css`
			position: sticky;
			left: -1px;
			background: ${wit};
		`};
`;

export const Td = styled.td`
	${cellStyle};
	color: ${grijs.darkest};
`;
